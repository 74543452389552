import { Alert, Card, CardContent, CardHeader } from '@mui/material';
import { Datagrid, DateField, DateInput, List, NumberField, useListContext, TextField, ArrayInput, SelectInput, useGetList } from 'react-admin';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { addDays } from 'date-fns';
import _ from 'lodash';

const postFilters = [
    <DateInput source="Created.$ge" label="Created From" defaultValue={addDays(new Date(), -1)} />,
    <DateInput source="Created.$le" label="Created To" defaultValue={new Date()} />,
    <SelectInput source="Service" label="Service" choices={[
        { id: 'Dummy', name: 'Dummy' },
        { id: 'JustAI', name: 'JustAI' },
        { id: 'JustAiRRP', name: 'JustAiRRP' },
        { id: 'Viber', name: 'Viber' },
        { id: 'ViberDirect', name: 'ViberDirect' },
        { id: 'JicpCC', name: 'JicpCC' },
        { id: 'JicpRRP', name: 'JicpRRP' },
    ]}/>
];

const dateFormatter = (date: Date): string =>
    new Date(date).toLocaleString();

export const SmppperformanceList = () => {
    const { data: smppStatus } = useGetList("smppcheck");    

    return (


        <List pagination={false} perPage={1000} filters={postFilters}>
            <>
                <Card>
                    <CardContent>
                        {
                            smppStatus && smppStatus[0].IsOpen ?
                            <Alert severity="success">SMPP Port Status is OK</Alert> :
                            <Alert severity="error">SMPP Port Error</Alert>
                        }                        
                    </CardContent>
                </Card>
                
                <SmppChart></SmppChart>

                <Datagrid  bulkActionButtons={false} optimized sx={{ '& .column-Count': { textAlign: 'left' }}}>
                    <DateField source='Interval' showTime />
                    <NumberField source='Count' label='Msg. Count' />
                </Datagrid>
            </>
        </List>
    );
}

export const SmppChart = () => {
    const { data, isLoading } = useListContext();
    if (isLoading) return null;
    return (
        <Card>
            <CardHeader title={'SMPP Performance'} />
            <CardContent>
                <div style={{ width: '100%', height: 400 }}>
                    <ResponsiveContainer>
                        <AreaChart data={data}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                                dataKey="Interval"
                                name="Date"
                                tickFormatter={dateFormatter}
                            />
                            <YAxis dataKey="Count" name="Msg. Count"  label={{ value: 'Messages Count', angle: -90, position: 'insideLeft' }} />
                            <Tooltip />
                            <Area type="monotone" data={data} dataKey="Count" stroke="#005396" fill="#005396" />;
                        </AreaChart>
                    </ResponsiveContainer>
                </div>
            </CardContent>
        </Card>
    );
};
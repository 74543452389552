import { useCallback } from "react";
import { BooleanInput, Create, FormDataConsumer, maxValue, minValue, NullableBooleanInput, NumberInput, required, SimpleForm, TextInput, useCreate, useNotify, useRedirect } from "react-admin";



export const FeatureFlagsCreate = () => {    
    return (
        <Create>
            <SimpleForm>      
                <TextInput source="Id" />   
                <TextInput source="Name" />   
                <BooleanInput source="IsEnabled" />
            </SimpleForm>
        </Create>
    );
}
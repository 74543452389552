import { ArrayField, Datagrid, EditContextProvider, NumberField, Pagination, SaveButton, SimpleForm, SimpleList, SimpleShowLayout, SingleFieldList, TextField, UrlField, useEditController, useNotify, useRecordContext, useUpdate } from "react-admin"
import { QueuekickState } from "../../../models/queuekickState";
import RestartAltOutlinedIcon from '@mui/icons-material/RestartAltOutlined';

export const RunExportShow = () =>{
    var viewRecord = useRecordContext();
    var arr = JSON.parse(viewRecord.Result);
    var successResult = Array.isArray(arr);
    var result;
    if (successResult){
        
        result = arr.map((item: string, index: number) => {
            return {
                id: index,
                url: item
            }
        });
    }
    var editProps = useEditController({ resource: 'qk_run_export', id: viewRecord.id });
    const notify = useNotify();
    const [update] = useUpdate();

    async function handleClick (e : any) {
        e.preventDefault();
        console.log(editProps);
        await update(
            'qk_run_export',
            { 
                id: editProps.record.id, 
                data: {...editProps.record, 
                    State: QueuekickState.Pending,
                    MaxAttemptCount: editProps.record.MaxAttemptCount + 1
                } 
            },
            { 
                onSuccess: () =>  notify('Restarted', {type: 'success'}),
                onError: () => notify('Cannot be restarted', {type:'error'})
            }
        );
        
    };
    return (
        <SimpleShowLayout>
            {successResult &&
                
                    <ArrayField record={{Urls: result}} source='Urls'>
                       
                        <SimpleList linkType={false} primaryText={<UrlField source="url" target={'_blank'}/>} />                        
                    </ArrayField>
            }
            {!successResult &&
                <TextField source='Result' emptyText={"No result"}/>
            }
            <EditContextProvider value={editProps}>
                <SimpleForm toolbar={false}>
                    <SaveButton onClick={handleClick} type="button" alwaysEnable label="Restart" icon={<RestartAltOutlinedIcon />}/>
                </SimpleForm>
            </EditContextProvider>

        </SimpleShowLayout>
    );
}
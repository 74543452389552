import { List, TextField, BulkDeleteWithConfirmButton, DatagridConfigurable, DateField, usePermissions, TextInput } from 'react-admin';
import * as XLSX from "xlsx";

const exporter = (data: any) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Imports');
    XLSX.writeFile(workbook, 'Imports.xlsx');
}

const importFilters = [
    <TextInput label='pe.forms.import.filterLabels.comment'
        source="Comment.$contains"
        resettable />,
    <TextInput label='pe.forms.import.filterLabels.status'
        source="Status.$contains"
        resettable />,
];

export const ImportList = (props: any) => {
    return (
        <div>
            <List 
             filters={importFilters} exporter={exporter}
             sort={{ field: "Updated", order: "DESC" }}>
                <DatagridConfigurable rowClick="edit"
                    bulkActionButtons={<BulkDeleteWithConfirmButton mutationMode='optimistic' />}
                    omit={[
                        'Code',
                        'CreatedBy',
                        'UpdatedBy',
                    ]}>
                    <TextField source="id" />
                    <TextField source="Code" />
                    <TextField source="Collection" />
                    <TextField source="Comment" />
                    <DateField source='Created' />
                    <TextField source="CreatedBy" />
                    <TextField source="UpdatedBy" />
                    <DateField source='Updated' />
                    <TextField source='Status' />
                </DatagridConfigurable>
            </List>
        </div>)
};
import { Datagrid, DateField, DateInput, FunctionField, List, NumberField, TextInput } from "react-admin";
import { QueuekickState } from "../../../models/queuekickState";
import { SendToMindboxShow } from "./sendToMindboxShow";


const postFilters = [
    <TextInput source="Params.$contains" label="Params" />,
    <DateInput source="Created.$ge" label="Created From" />,
    <DateInput source="Created.$le" label="Created To" />,
    <DateInput source="Updated.$ge" label="Updated From" />,
    <DateInput source="Updated.$le" label="Updated To" />,
];


export const SendToMindboxList = () =>{
    return (
        <List filters={postFilters} exporter={false} title='Subscriptions from Mule to Mindbox'>
            <Datagrid bulkActionButtons={false} expand={<SendToMindboxShow/>} rowClick='expand' expandSingle>
                <DateField source='Created' showTime />
                <DateField source='StartTime' label='Started' showTime />
                <DateField source='Updated' label='Last update' showTime />
                <FunctionField label='State' render={(record: any) => QueuekickState[record.State]} />
                <NumberField source='CurrentAttemptCount' label='Current attempt' />
            </Datagrid>
        </List>
    );
}
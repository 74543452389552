import { useCallback } from "react";
import { ArrayField, Datagrid, EditContextProvider, FileField, NumberField, Pagination, SaveButton, SimpleForm, SimpleList, SimpleShowLayout, SingleFieldList, TextField, UrlField, useEditController, useNotify, useRecordContext, useUpdate } from "react-admin"
import { useFormContext } from "react-hook-form";
import { QueuekickState } from "../../../models/queuekickState";
import RestartAltOutlinedIcon from '@mui/icons-material/RestartAltOutlined';

export const LastActiveDeviceSynchronizationShow = () =>{
    var viewRecord = useRecordContext();
    var editProps = useEditController({ resource: 'qk_datasync_sync_lastactivedevice', id: viewRecord.id });
    const notify = useNotify();
    const [update] = useUpdate();

    async function handleClick (e : any) {
        e.preventDefault();
        console.log(editProps);
        await update(
            'qk_datasync_sync_lastactivedevice',
            {
                id: editProps.record.id,
                data: {...editProps.record,
                    State: QueuekickState.Pending,
                    MaxAttemptCount: editProps.record.MaxAttemptCount + 1
                }
            },
            {
                onSuccess: () =>  notify('Restarted', {type: 'success'}),
                onError: () => notify('Cannot be restarted', {type:'error'})
            }
        );

    };
    return viewRecord.State !=5 ? (
        <SimpleShowLayout sx={{ maxWidth: "calc(100vw - 385px)", wordBreak: "break-all" }}>
            <TextField source='Result' emptyText={"No result"}/>
            <TextField source='Params' emptyText={"No params"}/>
            <EditContextProvider value={editProps}>
                <SimpleForm toolbar={false}>
                    <SaveButton onClick={handleClick} type="button" alwaysEnable label="Restart" icon={<RestartAltOutlinedIcon />}/>
                </SimpleForm>
            </EditContextProvider>
        </SimpleShowLayout>
    ) : (
        <SimpleShowLayout sx={{ maxWidth: "calc(100vw - 385px)", wordBreak: "break-all" }}>
            <TextField source='Result' emptyText={"No result"}/>
            <TextField source='Params' emptyText={"No params"}/>
            <EditContextProvider value={editProps}>
            </EditContextProvider>
        </SimpleShowLayout>
    );
}
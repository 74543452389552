import RestartAltOutlinedIcon from '@mui/icons-material/RestartAltOutlined';
import { BulkUpdateButton } from 'react-admin';

const resetQueuekick = { State: 1, CurrentAttemptCount: 0, MaxAttemptCount: 1 };

export const RestartQueukickButton = () => {    
    return (
        <>
            <BulkUpdateButton label="Restart Selected" data={resetQueuekick} icon={<RestartAltOutlinedIcon/>} mutationMode="pessimistic" />
        </>        
    )
};
import enMessages from 'ra-language-english';
import { cp } from './translations/cp';
import { ra } from './translations/ra';
import * as lodash from 'lodash';
import { audit } from './translations/audit';


const translations: any = {enMessages};

const messages = {
    
    resources:{
        ...audit,
    },
    ...cp
};

function transform(data: any, lang: string) {
    //console.log(data);
    // base case
    if (typeof data === 'object' && data.hasOwnProperty('en')) {
      return data[lang] ? data[lang] : data['en'];
    }
  
    // object but not base case
    if (typeof data === 'object') {
      for (let key in data) {
        data[key] = transform(data[key], lang);
      }
    }
  
    // primitive, so return as is
    return data;
  }
  
  export function getArray (lang: string) {

    var toTransform = JSON.parse(JSON.stringify(messages));
    var langArray = transform(toTransform, lang);
    var raTransformed = transform(JSON.parse(JSON.stringify(ra)), lang)
    //var merged = {...translations[lang+'Messages'], ...raTransformed}
    var merged = lodash.merge(translations[lang+'Messages'], raTransformed);
    return {
        //...translations[lang+'Messages'],
        ...merged,
        ...langArray
    }
  }
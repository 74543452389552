import saveAs from "file-saver";
import DownloadIcon from '@mui/icons-material/Download';
import { Button, useRecordContext } from "react-admin";
import { Grid } from "@mui/material";

interface IProductListGridProps {
    disabled?: boolean;
}

export const LogDownloadButton = () => {
    const recordContext = useRecordContext();
    const handleClick = (event: any) => {
        event.preventDefault();
        const fileControllerUrl = process.env.REACT_APP_FILES_API_URL;
        const urlString = recordContext.ImportResultsFileName;
        
        const url = new URL(urlString);
        const { search } = url;
        const correctedUrl = `${fileControllerUrl}${search}`;
        const saveFileName = urlString.split("/").pop().split("=").pop();
        const auth = localStorage.getItem('auth');
        const headers = new Headers();
        if (auth != null) {
            headers.set('Authorization', 'Basic ' + auth);
        }
        fetch(correctedUrl, {
            method: 'GET',
            headers: headers,
        })
            .then(function (response) {
                return response.blob();
            }
            )
            .then(function (blob) {
                saveAs(blob, saveFileName);
            })
            .catch(error => {

            })
    }

    return <Button variant='outlined' label="pe.forms.import.DownloadLogFile" size='small' onClick={(e) => handleClick(e)} >
        <DownloadIcon />
    </Button>;

}

import { BooleanInput, Edit, FormDataConsumer, maxValue, minValue, NullableBooleanInput, NumberInput, required, SimpleForm, TextInput } from "react-admin";

export const RulesEdit = () => {
    return (
        <Edit>
            <SimpleForm>
                <span><TextInput source="Code" disabled/>   <NullableBooleanInput source="Dynamic" defaultValue={false} /></span>       
                <TextInput source="Campaign" disabled/>
                <NumberInput 
                    label='Life time, days (max 270)' 
                    source="LifeTime" 
                    min={1} 
                    max={270} 
                    validate={[required(), minValue(1), maxValue(270)]}/>
                
                <NumberInput 
                    source='UsageLimit'
                    label='Usage limit (max 1000)'  
                    min={1} 
                    max={1000}
                    
                    validate={[
                        required(), 
                        minValue(1), 
                        maxValue(1000)
                    ]}/>

            </SimpleForm>
        </Edit>
    );
}
import { Datagrid, DateField, DateInput, FunctionField, List, ListContextProvider, SelectField, SelectInput, TextField, TextInput, UrlField, useList, useRecordContext } from "react-admin";
import { SmppStatuses } from "../../models/smppStatuses";
import * as XLSX from 'xlsx';

const IntegritylogsFilters = [
    <TextInput source='SpiceId.$contains' resettable />,
    <TextInput source='RequestsNumber.$contains' resettable />,
    <DateInput source="CreateDate.$ge" label="Create Date From" />,
    <DateInput source="CreateDate.$le" label="Create Date To" />,
    <DateInput source="LastRequestDate.$ge" label="Last Request Date From" />,
    <DateInput source="LastRequestDate.$le" label="Last Request Date To" />,
];

const exporter = (auditItems: any[]) => {
    const worksheet = XLSX.utils.json_to_sheet(auditItems);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'AuditApiItems');
    XLSX.writeFile(workbook, 'AuditApiItems.xlsx');
}

export const IntegritylogsList = () =>{
    return (
        <List filters={IntegritylogsFilters} exporter={exporter}>
            <Datagrid rowClick="show" sort={{ field: "CreateDate", order: "DESC" }}>
                <TextField source="SpiceId" />
                <TextField source="RequestsNumber" />
                <DateField source='CreateDate' showTime={true} />
                <DateField source='LastRequestDate' showTime={true} />
            </Datagrid>
        </List>
    );
}
import RestartAltOutlinedIcon from '@mui/icons-material/RestartAltOutlined';
import { BulkUpdateButton, Button, Confirm, useListContext, useNotify, useRefresh, useResourceContext, useUnselectAll, useUpdateMany } from 'react-admin';
import { httpClient } from '../data/httpClient';
import { useCallback, useState } from 'react';
import { useMutation } from 'react-query';

const resetQueuekick = { State: 1, CurrentAttemptCount: 0, MaxAttemptCount: 1 };

export const RestartAllQueukickButton = () => {
    const { filterValues } = useListContext();
    const [open, setOpen] = useState(false);
    const resource = useResourceContext();
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll(resource);

    const { mutate, isLoading } = useMutation(
        () =>  httpClient(`${process.env.REACT_APP_API_URL}/${resource}/resetqueuekick`, {
            method: 'POST',
            body: JSON.stringify({
                Filter: JSON.stringify(filterValues)
            }),
        }).then(data => {
            refresh();
            notify('Items updated');
            unselectAll();
        })
    );

    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);

    const handleConfirm = () => {
        mutate();
        setOpen(false);
    };

    if (JSON.stringify(filterValues) === "{}")
    return <></>
    
    return  (
        <>
            <Button
                label="Restart All Filtered"
                onClick={handleClick}
            >
                <RestartAltOutlinedIcon />
            </Button>
            <Confirm
                isOpen={open}
                loading={isLoading}
                title="Restart All Filtered"
                content="Are you sure you want to restart ALL these items?"
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />
        </>        
    )
};
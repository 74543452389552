import polyglotI18nProvider from 'ra-i18n-polyglot';
import { getArray } from './stringsProvider';
const en = getArray('en');
const ru = getArray('ru');
const translations = { en, ru };
//console.log(ru);
export const i18nProvider = polyglotI18nProvider(
    locale => translations[locale as keyof typeof translations],
    'en', // default locale, { locale: 'ru', name: 'Русский' }
    [{ locale: 'en', name: 'English' }],
    { allowMissing: true }
);
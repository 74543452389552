import { fetchUtils } from 'react-admin';

export const httpClient = (url : string, options : any = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }

    //Basic Authentication
    const auth = localStorage.getItem('auth');
    if (auth != null) {
        options.headers.set('Authorization', 'Basic ' + auth);
    }

    //JWT
    /*const token = localStorage.getItem('jwttoken');
    if (token != null) {
        options.headers.set('Authorization', 'Bearer ' + token);
    }*/

    return fetchUtils.fetchJson(url, options);
}

import { Datagrid, DateField, FunctionField, List, DateInput, NumberField, TextInput, SelectInput } from "react-admin";
import { QueuekickState } from "../../../models/queuekickState";
import { QueueKickStatuses } from "../../../models/queuekickStatuses";
import { ConsumerProductInspectionRequestsSynchronizationShow } from "./consumerProductInspectionRequestsSynchronizationShow";
import { RestartQueukickButton } from "../../../components/RestartQueukickButton";


const postFilters = [
    <TextInput source="Params.$contains" label="Params" />,
    <DateInput source="Created.$ge" label="Created From" />,
    <DateInput source="Created.$le" label="Created To" />,
    <DateInput source="Updated.$ge" label="Updated From" />,
    <DateInput source="Updated.$le" label="Updated To" />,
    <SelectInput source="State" choices={QueueKickStatuses} />
];

export const ConsumerProductInspectionRequestsSynchronizationList = () =>{
    return (
        <List filters={postFilters} exporter={false} title='Syncing consumers in Mindbox'>
            <Datagrid bulkActionButtons={<RestartQueukickButton />} expand={<ConsumerProductInspectionRequestsSynchronizationShow/>} rowClick='expand' expandSingle>
                <DateField source='Created' showTime />
                <DateField source='StartTime' label='Started' showTime />
                <DateField source='Updated' label='Last update' showTime />
                <FunctionField label='State' render={(record: any) => QueuekickState[record.State]} /> 
                <NumberField source='CurrentAttemptCount' label='Current attempt' />
            </Datagrid>
        </List>
    );
}

import { Grid, LinearProgress } from "@mui/material";
import { FormDataConsumer, TextInput, Edit, usePermissions, useTranslate, FormTab, TabbedForm, Datagrid, TextField, ArrayField } from "react-admin";
import { permissionService } from "../../auth/permissionsService";
import { LogDownloadButton } from "./components/LogDownloadButton";
import { useEffect } from "react";

export class ImportStatuses {
    static readonly New = "New";
    static readonly Completed = "Completed";
    static readonly CompletedWithErrors = "CompletedWithErrors";
    static readonly Failed = "Failed";
    static readonly InProgress = "InProgress";
}

export const ImportEdit = () => {
    const { permissions } = usePermissions();
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const t = useTranslate();
    return (
        <Edit redirect={false} mutationMode="pessimistic">
            <TabbedForm>
                <FormTab label="Edit import">
                    <Grid container spacing={2}>
                        <Grid item md={6} xs={12}>
                            <TextInput source="Code" disabled fullWidth />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item md={6} xs={12}>
                            <TextInput source="Collection" fullWidth disabled />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextInput source="Attachments.Title" fullWidth disabled />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextInput source="Comment" multiline fullWidth />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextInput source="Status" fullWidth disabled sx={{ display: 'none' }} />
                            <TextInput source='Status' disabled />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} direction="row">
                        <Grid item md={6} xs={12}>
                            <TextInput source="Created" fullWidth disabled />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextInput source="CreatedBy" fullWidth disabled />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextInput source="Updated" fullWidth disabled />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextInput source="UpdatedBy" fullWidth disabled />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} direction="row">
                        <Grid item md={3}  xs={12}>
                            <TextInput source="SuccessfullyProcessedRecords" fullWidth disabled />
                        </Grid>
                        <Grid item md={3} xs={12}>
                            <TextInput source="UnsuccessfullyProcessedRecords" fullWidth disabled />
                        </Grid>
                        <Grid item md={3} xs={12}>
                            <TextInput source="ProcessedRecords" fullWidth disabled />
                        </Grid>
                        <Grid item md={3} xs={12}>
                            <TextInput source="TotalRecords" fullWidth disabled />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} direction="row" sx={{ marginBottom: 2 }}>
                        <Grid item md={12} xs={12}>
                            <FormDataConsumer>
                                {({ formData, ...rest }) => formData.Status &&
                                    formData.Status != ImportStatuses.New &&
                                    <LinearProgress variant="buffer"
                                        value={formData.SuccessfullyProcessedRecords * 100 / formData.TotalRecords}
                                        valueBuffer={formData.ProcessedRecords * 100 / formData.TotalRecords}
                                        color="success"
                                        sx={{
                                            height: 12,
                                            border: 1,
                                            '& .MuiLinearProgress-bar2Buffer': {
                                                backgroundColor: 'firebrick',
                                            },
                                        }} />
                                }
                            </FormDataConsumer>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} direction="row" >
                        <Grid item md={6} xs={12}>
                            <FormDataConsumer>
                                {({ formData, ...rest }) => formData.Status &&
                                    (formData.Status == ImportStatuses.Completed ||
                                        formData.Status == ImportStatuses.CompletedWithErrors) &&
                                    <LogDownloadButton />
                                }
                            </FormDataConsumer>
                        </Grid>
                    </Grid>
                </FormTab>
            </TabbedForm >
        </Edit >)
};
import { ArrayField, BooleanField, Button, Datagrid, DateField, Edit, FunctionField, ListContextProvider, NumberField, Pagination, ReferenceManyField, RichTextField, Show, SimpleShowLayout, SingleFieldList, TextField, TextInput, useList, UseListOptions, useRecordContext } from "react-admin";
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { Card, CardContent, Divider, Grid, Tooltip } from "@mui/material";
import { useState } from "react";


export const TicketShow = () => {
    var record = useRecordContext();
    // var requests: TicketRequest[] = record.Requests.map((item: TicketRequest, index: number) => {
    //     return {
    //         id: index + 1,
    //         Created: item.Created,
    //         Success: item.Success,
    //         ErrorCode: item.ErrorCode,
    //         ErrorMessage: item.ErrorMessage       
    //     }
    // });
    // var listContext = useList( { data: requests, sort: {field: 'Created', order: 'ASC'} } );
    var ticketWithBrakes = record?.Ticket?.match(/.{1,70}/g).join('<br />') ?? '';
    function copyToClipboard(textToCopy : string) : void {
        // navigator clipboard api needs a secure context (https)
        if (navigator.clipboard && window.isSecureContext) {
            // navigator clipboard api method'
            navigator.clipboard.writeText(textToCopy);
        } else {
            // text area method
            let textArea = document.createElement("textarea");
            textArea.value = textToCopy;
            // make the textarea out of viewport
            textArea.style.position = "fixed";
            textArea.style.left = "-999999px";
            textArea.style.top = "-999999px";
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
                // here the magic happens
                document.execCommand('copy');
                textArea.remove();
            ;
        }
    }
    return (
        <>
            {
                record?.Ticket != null &&
                <SimpleShowLayout>
                    {/* <Card> */}
                    {/* <CardContent> */}
                    <RichTextField source="Code" fontFamily={'Consolas'}/>
                    <Divider/>
                    <Grid container alignItems='center' justifyContent='center' marginBottom={'20px'}>
                        <Grid item xs={8}>
                            <RichTextField source="Ticket"
                                           record={{Ticket: ticketWithBrakes}}
                                           fontFamily={'Consolas'}
                                           label={'Ticket'}/>
                        </Grid>
                        {/* <RichTextField source="Ticket" fontFamily={'Consolas'}/> */}
                        <Grid item xs={4}>
                            <Button
                                variant="text"
                                startIcon={<ContentCopyOutlinedIcon/>}
                                label='Copy to clipboard'
                                onClick={() => {
                                    copyToClipboard(record.Ticket);
                                }}/>
                        </Grid>
                    </Grid>
                    <Divider/>
                    {/* <ListContextProvider value={listContext}> */}
                    {/* <ArrayField source='Requests'> */}
                    <ReferenceManyField reference="mts_ticketrequests"
                                        target="TicketId"
                                        perPage={10}
                                        sort={{field: 'Created', order: 'ASC'}}
                                        label={false}>
                        <Datagrid bulkActionButtons={false}>
                            <DateField source="Created" showTime/>
                            <BooleanField source="Success"/>
                            <NumberField source="ErrorCode"/>
                            <TextField source="ErrorMessage"/>
                        </Datagrid>
                        <Pagination/>
                    </ReferenceManyField>
                    {/* </ListContextProvider> */}
                    {/* </ArrayField> */}
                    {/* </CardContent> */}
                    {/* // </Card> */}
                </SimpleShowLayout>
            }

            {
                record?.Ticket == null &&
                <SimpleShowLayout>
                    <TextField emptyText="The ticket doesn't matter"/>
                </SimpleShowLayout>
            }
        </>
    );
}
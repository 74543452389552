import { Chip } from "@mui/material";
import { useRecordContext, useTranslate } from "ra-core";

export const AuditEditFields = (props:any) => {
    const record = useRecordContext();
    const t = useTranslate();
    if (!record.KeysDifference) return null;
    return (
        <>
            {record.KeysDifference.map((item:any) => (
                <Chip key={'AEF'+item} label={t('resources.'+record.Collection+'.fields.'+item)} />
            ))}
        </>
    )
};
import { AuditValueRule } from "./AuditValueRule";


interface IAuditValueProps {
    value: any,
    isNewValue: 0 | 1
}
export const AuditValue = (props: IAuditValueProps) => { //TO-DO refactor as service
    const { value, isNewValue } = props;

    if (value && value.length > isNewValue && value[isNewValue]) {
        if (value[isNewValue].constructor === Array && value[isNewValue].length) {
            if (value[isNewValue][0].hasOwnProperty('Rules')) {
                return (
                    <>
                        {value[isNewValue].map((item: any) => (
                            <div>
                                {item.Rules.map((r: string) => <AuditValueRule id={r} />)}
                            </div>
                        ))}
                    </>
                )
            }
            else {
                return (
                    <>
                        {value[isNewValue].map((r: string) => r).join(' ')}
                    </>
                )
            }
        }
        else {
            return (
                <>
                    {value[isNewValue]}
                </>
            )
        }
    }
    else {
        return (
            <>
            </>
        )
    }
}
import { Datagrid, DateField, DateInput, FunctionField, List, ListContextProvider, SelectField, SelectInput, TextField, TextInput, UrlField, useList, useRecordContext } from "react-admin";
import { SmppStatuses } from "../../models/smppStatuses";
import * as XLSX from 'xlsx';

const smppFilters = [
    <TextInput source='Code.$eq' resettable />,
    <TextInput source='From.$contains' resettable />,
    <TextInput source='To.$contains' resettable />,
    <TextInput source='Service.$contains' resettable />,
    <TextInput source='Status.$contains' resettable />,
    <SelectInput source="Status" choices={SmppStatuses} />,
    <DateInput source="Created.$ge" label="Created From" />,
    <DateInput source="Created.$le" label="Created To" />,
    <TextInput source='RootActionId.$eq' resettable />
];

const exporter = (auditItems: any[]) => {
    const worksheet = XLSX.utils.json_to_sheet(auditItems);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'SMPPItems');
    XLSX.writeFile(workbook, 'SMPPItems.xlsx');
}

export const SmppList = () =>{
    return (
        <List filters={smppFilters} exporter={exporter}>
            <Datagrid rowClick="show" sort={{ field: "Created", order: "DESC" }}>
                <DateField source='Created' showTime={true} />
                <TextField source="From" />
                <TextField source="To" />
                <TextField source="Service" />
                <TextField source="RootActionId" />
                <SelectField source="Status" choices={SmppStatuses} />
            </Datagrid>
        </List>
    );
}
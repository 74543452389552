import { Datagrid, DateField, DateTimeInput, Edit, FunctionField, ListBase, Pagination, ReferenceManyField, SaveButton, SelectInput, ShowButton, SimpleForm, TextField, TextInput, useRecordContext } from "react-admin";
import { Grid } from "@mui/material";
import { SmppStatuses } from "../../models/smppStatuses";


export const S3serviceShow = () => {
    var record = useRecordContext(); //<SaveButton sx={{ margin: 2 }} label="Save" type="button" alwaysEnable  transform={(data: any) => ({ ...data, NeedUpdate: true })}/>
    return (
        <Edit>
            <SimpleForm toolbar={false} >
                <Grid container spacing={2} direction="row">
                    <Grid item md={6} xs={12}>
                        <TextInput source="ServiceName" disabled fullWidth />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <DateTimeInput source="LastRunDate" disabled fullWidth defaultValue={"dd.mm.yyyy hh:mm"}/>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <TextInput source="State" disabled fullWidth />
                    </Grid>
                </Grid>
                <Grid container spacing={2} direction="row">
                    <Grid item xs={12}>
                        <TextInput source="ErrorText" multiline disabled fullWidth />
                    </Grid>
                </Grid>
                
                <Grid container spacing={2} direction="row">
                    <Grid item xs={12}>
                        <ReferenceManyField label="S3Log" reference="s3log" target="ServiceName">
                                <Datagrid empty={<span>No data</span>} width="100%" bulkActionButtons={false}>
                                    <DateField source='ProcessingDate' showTime />
                                    <TextField source="FileName" fullWidth />
                                </Datagrid>
                                <Pagination />
                        </ReferenceManyField >
                    </Grid>
                </Grid>

                
            </SimpleForm>
        </Edit>
    );
}
import { ThemeOptions } from "@mui/material";
import { defaultTheme } from "react-admin";

export interface CustomThemeOptions extends ThemeOptions {
    sidebar?: {
        width?: number;
        closedWidth?: number;
    };
}

export const lightTheme: CustomThemeOptions = {
    ...defaultTheme,
    components: {
        ...defaultTheme.components,
        MuiTable: {
            styleOverrides: {
                root: {
                    ".MuiTableCell-head": {
                        fontWeight: '600'
                    },
                }
            }
        },
        MuiMenu: {
            styleOverrides: {
                root: {
                    'MuiButtonBase-root': {
                        background: '#00FFF0'
                    }
                }
            }
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    ":root": {
                        background: '#00FFF0'
                    }
                }
            }
        },
        MuiToolbar: {
            styleOverrides: {
                gutters: {
                    '&.MuiToolbar-root:has(form + span + div.MuiToolbar-root)': {
                        flexDirection: 'column',
                        'form': {
                            alignSelf: 'flex-start'
                        },
                        '.MuiToolbar-gutters': {
                            minHeight: 'auto'
                        }
                    }
                }
            }
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    '.MuiGrid-container.MuiGrid-root': {
                        marginTop: '0px',
                        '>.MuiGrid-root': {
                            paddingTop: '0px'
                        }
                    }
                }
            }
        }
    },
    typography: {
        fontFamily: [
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
    palette: {
        mode: 'light',
        primary: {
            main: '#005396',
        },
        secondary: {
            main: '#005396',
        },
        success: {
            main: '#02836e',
        },
        warning: {
            main: '#D79D5B',
        },
        info: {
            main: '#009CDE',
        },
        text: {
            primary: '#393939',
        },
        background: {
            default: '#F5F5F5',
        },
    },
    sidebar: {
        width: 300,
        closedWidth: 56,
    },
};

export const darkTheme: CustomThemeOptions = {
    ...lightTheme,
    palette: {
        mode: 'dark',
    },
}
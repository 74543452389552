export const audit = {
    audit : {
        name: {
            en:'Audit |||| Audit',
            ru:'История изменений |||| История изменений'
        },
        empty: {
            en: 'List is empty yet.',
            ru: 'Список ещё пуст.'
        },
        invite: {
            en: 'Do you want to add one?',
            ru: 'Хотите создать первый элемент?'
        },
        fields: {
            Code: {
                en: 'Code'
            },
            Timestamp: {
                en: 'Timestamp',
                ru: 'Время изменения'
            },
            UserId : {
                en: 'User',
                ru: 'Пользователь'
            },
            Collection:{
                en: 'Collection',
                ru: 'Коллекция'
            },
            Difference: {
                en: 'Difference',
                ru: 'Изменения'
            },
            OldValue:{
                en: 'Old Value',
                ru: 'Старое значение'
            },
            NewValue:{
                en: 'New Value',
                ru: 'Новое значение'
            },
            AuditType:{
                en: 'Audit Type',
                ru: 'Тип изменения'
            },
            KeysDifference:{
                en: 'Keys Difference',
                ru: 'Измененные поля'
            }
        },
        statuses:{
            Edit:{
                en: 'Edit',
                ru: 'Редактирование'
            },
            Create:{

                en: 'Create',
                ru: 'Создание'
            },
            Delete:{
                en: 'Delete',
                ru: 'Удаление'
            }
        }
    }
}
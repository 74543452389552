import { Datagrid, DateField, FunctionField, List, ListContextProvider, TextField, TextInput, UrlField, useList, useRecordContext } from "react-admin";
import { ElkAlertShow } from "./elkAlertShow";

const ticketFilters = [
    <TextInput source='Ticket.$contains' resettable />,
    <TextInput source='Source.$contains' resettable />,
    <TextInput source='Campaign.$contains' resettable />
];

export const ElkAlertList = () =>{
    return (
        <List filters={ticketFilters} exporter={false}>
            <Datagrid expand={<ElkAlertShow/>} rowClick='expand' bulkActionButtons={false} expandSingle >
                <FunctionField label='Code' render={(record: any) => record.Code.substring(0, 10)+'...'} />
                <DateField source='Created' showTime={true} />
                {/* <FunctionField label='Count' render={(record: any) => record.Requests.length} /> */}
            </Datagrid>
        </List>
    );
}
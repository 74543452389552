// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_versionFloat__xml-Q {\n  position: sticky;\n  bottom: 20px;\n  left: 20px;\n  width: max-content;\n  pointer-events: none;\n  z-index: 1;\n  color: rgba(0, 0, 0, 0.33);\n}", "",{"version":3,"sources":["webpack://./src/styles/style.module.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,YAAA;EACA,UAAA;EACA,kBAAA;EACA,oBAAA;EACA,UAAA;EACA,0BAAA;AACJ","sourcesContent":[".versionFloat {\n    position: sticky;\n    bottom: 20px;\n    left: 20px;\n    width: max-content;\n    pointer-events: none;\n    z-index: 1;\n    color: rgba(0,0,0,0.33);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"versionFloat": "style_versionFloat__xml-Q"
};
export default ___CSS_LOADER_EXPORT___;

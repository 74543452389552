import { Datagrid, DateField, FunctionField, List, DateInput, NumberField, TextInput, SelectInput, FilterButton, TopToolbar } from "react-admin";
import { QueuekickState } from "../../../models/queuekickState";

import { QueueKickStatuses } from "../../../models/queuekickStatuses";
import { ConsumerFirstAcquisitionSynchronizationShow } from "./consumerFirstAcquisitionSynchronizationShow";
import { RestartQueukickButton } from "../../../components/RestartQueukickButton";
import { RestartAllQueukickButton } from "../../../components/RestartAllQueukickButton";






const postFilters = [
    <TextInput source="Params.$contains" label="Params" />,
    <DateInput source="Created.$ge" label="Created From" />,
    <DateInput source="Created.$le" label="Created To" />,
    <DateInput source="Updated.$ge" label="Updated From" />,
    <DateInput source="Updated.$le" label="Updated To" />,
    <SelectInput source="State" choices={QueueKickStatuses} />
];

const ListActions = () => (
    <TopToolbar>
        <FilterButton />
        <RestartAllQueukickButton />
    </TopToolbar>
);

export const ConsumerFirstAcquisitionSynchronizationList = () =>{
    return (
        <List filters={postFilters} exporter={false} title='Syncing consumers first acquisition in Mindbox'  actions={<ListActions />}>
            <Datagrid bulkActionButtons={<RestartQueukickButton />} expand={<ConsumerFirstAcquisitionSynchronizationShow/>} rowClick='expand' expandSingle>
                <DateField source='Created' showTime />
                <DateField source='StartTime' label='Started' showTime />
                <DateField source='Updated' label='Last update' showTime />
                <FunctionField label='State' render={(record: any) => QueuekickState[record.State]} /> 
                <NumberField source='CurrentAttemptCount' label='Current attempt' />
            </Datagrid>
        </List>
    );
}
import { Datagrid, DateField, FunctionField, List, ListContextProvider, NumberField, TextField, TextInput, UrlField, useList, useRecordContext } from "react-admin";
import { QueuekickState } from "../../../models/queuekickState";
import { DownloadFilesShow } from "./downloadFilesShow";


export const DownloadFilesList = () =>{
    return (
        <List exporter={false} title='Mindox exports runs'>
            <Datagrid bulkActionButtons={false} expand={<DownloadFilesShow/>} rowClick='expand' expandSingle>
                <DateField source='Created' showTime />
                <DateField source='StartTime' label='Started' showTime />
                <DateField source='Updated' label='Last update' showTime />
                <FunctionField label='State' render={(record: any) => QueuekickState[record.State]} /> 
                <NumberField source='CurrentAttemptCount' label='Current attempt' />
            </Datagrid>
        </List>
    );
}
import { BooleanField, Datagrid, DateField, FunctionField, List, ListContextProvider, NumberField, RichTextField, TextField, TextInput, UrlField, useList, useRecordContext } from "react-admin";



export const FeatureFlagsList = () =>{
    return (
        <List exporter={false}>
            <Datagrid rowClick='edit'>
                <TextField source='Name' />
                <BooleanField source="IsEnabled" />
                <RichTextField source='Description' />
            </Datagrid>
        </List>
    );
}
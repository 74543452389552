import { Datagrid, List, NumberField, TextField } from "react-admin";

export const AutoFailuredList = () =>{
    return (
        <List exporter={false} title='Autorisation statistics'>
            <Datagrid >
                <TextField source='queue' label='Autorisation result'/>
                <NumberField source='count' />
            </Datagrid>
        </List>
    );
}
import { Grid, TextField } from "@mui/material";
import { DateTimeInput, FunctionField, Show, SimpleForm, Tab, TabbedShowLayout, TextInput, useTranslate } from "react-admin";
import { AuditShowTable } from "../../components/AuditShowTable";


export const AuditShow = () => {

    const t = useTranslate();
    return (
        <Show>
            <TabbedShowLayout>
                <Tab label="pe.forms.audit.changesTabLabel">
                    <SimpleForm toolbar={false} >
                        <Grid container spacing={2}>
                            <Grid container spacing={2} direction="row">
                                <Grid item md={6} xs={12}>
                                    <TextInput source="Code" disabled fullWidth />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <DateTimeInput source='Timestamp' disabled fullWidth />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} direction="row">
                                <Grid item md={6} xs={12}>
                                    <TextInput source="UserId" disabled fullWidth />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <TextInput source="Collection" disabled fullWidth />
                                </Grid>
                            </Grid>
                            <Grid container direction="row">
                                <Grid item md={12}>
                                    <AuditShowTable />
                                </Grid>
                            </Grid>
                        </Grid>
                    </SimpleForm>
                </Tab>
                <Tab label="pe.forms.audit.originalsTabLabel">
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FunctionField render={(record: { OldValue: any }) => {
                                return (
                                    <TextField label="OldValue" value={JSON.stringify(JSON.parse(record.OldValue), null, 2)} multiline disabled fullWidth />
                                )
                            }} />
                            <FunctionField render={(record: { NewValue: any }) => {
                                return (
                                    <TextField label="NewValue" value={JSON.stringify(JSON.parse(record.NewValue), null, 2)} multiline disabled fullWidth />
                                )
                            }} />
                        </Grid>
                    </Grid>
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
};
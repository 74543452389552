import { ArrayField, BulkExportButton, Datagrid, DateField, DateTimeInput, Edit, ExportButton, SimpleForm, TextField, TextInput, downloadCSV, useListContext } from "react-admin";
import { Grid } from "@mui/material";
import jsonExport from 'jsonexport/dist';


const DonwloadCSV = () => {
    const { data } = useListContext();
    return (
        <>
        <ExportButton onClick={() =>{
            jsonExport(data, {
                headers: ['Name', 'DateTime', 'ObjectId'] // order fields in the export
            }, (err, csv) => {
                downloadCSV(csv, 'IntegrityLogItems');
            });
        }} />
        </>
    );
};

export const IntegritylogsShow = () => {
    return (
        <Edit>
            <SimpleForm toolbar={false} >
                <Grid container spacing={2} direction="row">
                    <Grid item md={6} xs={12}>
                        <TextInput source="SpiceId" disabled fullWidth />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <TextInput source="RequestsNumber" disabled fullWidth />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <DateTimeInput source='CreateDate' disabled fullWidth />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <DateTimeInput source='LastRequestDate' disabled fullWidth />
                    </Grid>
                </Grid>
                <Grid container spacing={2} direction="row">
                    <Grid item xs={12}>
                        <ArrayField source="IntegrityLogItems">
                            <>
                            <DonwloadCSV />
                            <Datagrid bulkActionButtons={false}>
                                <TextField source="Name" />
                                <DateField source="DateTime"  showTime={true} />
                                <TextField source="ObjectId" />
                            </Datagrid>
                            </>
                        </ArrayField>
                    </Grid>
                </Grid>
            </SimpleForm>
        </Edit>
    );
}
import { Grid } from "@mui/material";
import { BooleanInput, DateTimeInput, Edit, FormDataConsumer, maxValue, minValue, NullableBooleanInput, NumberInput, required, SelectInput, SimpleForm, TextInput, TextField, RichTextField } from "react-admin";
import { SmppStatuses } from "../../models/smppStatuses";
import { RichTextInput } from "ra-input-rich-text";

export const FeatureFlagsEdit = () => {
    return (
        <Edit>
            <SimpleForm>
                <Grid container spacing={2} direction="row">
                    <Grid item md={12} xs={12}>
                        <TextInput source="Name" disabled/>   
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <BooleanInput source="IsEnabled" />
                    </Grid>
                </Grid>
                <Grid container spacing={2} direction="row">
                    <Grid item xs={6}>
                        <RichTextInput source="Description" fullWidth/>   
                    </Grid>
                </Grid>
            </SimpleForm>
        </Edit>
    );
}
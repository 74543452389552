import React from 'react';
import logo from './logo.svg';
import './App.css';
import {Admin, Resource} from 'react-admin';
import httpDataProvider from './data/httpDataProvider'
import authProvider from "./auth/basicAuthProvider";
import {TicketList} from './forms/ticket/ticketList';
import {TicketShow} from './forms/ticket/ticketShow';
import {RulesList} from './forms/rules/rulesList';
import {RulesEdit} from './forms/rules/rulesEdit';
import {RulesCreate} from './forms/rules/rulesCreate';
import LocalActivityOutlinedIcon from '@mui/icons-material/LocalActivityOutlined';
import RuleOutlinedIcon from '@mui/icons-material/RuleOutlined';
import {Layout} from "./layout";
import {ElkAlertList} from './forms/elkAlert/elkAlertList';
import styles from './styles/style.module.scss';
import {runExportList} from './forms/syncAll/runExport/runExportList';
import {RunExportShow} from './forms/syncAll/runExport/runExportShow';
import {DownloadFilesShow} from './forms/syncAll/downloadAndParce/downloadFilesShow';
import {DownloadFilesList} from './forms/syncAll/downloadAndParce/downloadFilesList';
import {SendToUcrmList} from './forms/syncAll/sendToUcrm/sendToUcrmList';
import {SendToUcrmShow} from './forms/syncAll/sendToUcrm/sendToUcrmShow';
import {SendToMindboxList} from "./forms/sync/sendToMindbox/sendToMindboxList";
import {SendToMindboxShow} from "./forms/sync/sendToMindbox/sendToMindboxShow";
import {ConsumersSynchronizationShow} from "./forms/datasync/consumerSynchronization/consumersSynchronizationShow";
import {ConsumersSynchronizationList} from "./forms/datasync/consumerSynchronization/consumersSynchronizationList";
import { AuditApiList } from './forms/auditapi/auditApiList';
import { AuditApiShow } from './forms/auditapi/auditApiShow';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUserOutlined';
import {PersonsSynchronizationList} from "./forms/datasync/personSynchronization/personsSynchronizationList";
import {PersonsSynchronizationShow} from "./forms/datasync/personSynchronization/personsSynchronizationShow";
import {
    RRPMarketingMessageContactsList
} from "./forms/datasync/rrpMarketingMessageContactSynchronization/rrpMarketingMessageContactList";
import {
    RRPMarketingMessageContactsShow
} from "./forms/datasync/rrpMarketingMessageContactSynchronization/rrpMarketingMessageContactShow";
import {ProductsSynchronizationShow} from "./forms/datasync/productSynchronization/productsSynchronizationShow";
import {ProductsSynchronizationList} from "./forms/datasync/productSynchronization/productsSynchronizationList";
import {OrdersSynchronizationList} from "./forms/datasync/orderSynchronization/ordersSynchronizationList";
import {OrdersSynchronizationShow} from "./forms/datasync/orderSynchronization/ordersSynchronizationShow";
import { SmppList } from './forms/smpp/smppList';
import { SmppShow } from './forms/smpp/smppShow';
import { ConsumerCommunicationSubscriptionSynchronizationList } from './forms/datasync/consumerCommunicationSubscriptionSynchronization/consumerCommunicationSubscriptionSynchronizationList';
import { ConsumerCommunicationSubscriptionSynchronizationShow } from './forms/datasync/consumerCommunicationSubscriptionSynchronization/consumerCommunicationSubscriptionSynchronizationShow';
import { ConsumerFirstAcquisitionSynchronizationShow } from './forms/datasync/consumerFirstAcquisitionSynchronization/consumerFirstAcquisitionSynchronizationShow';
import { ConsumerFirstAcquisitionSynchronizationList } from './forms/datasync/consumerFirstAcquisitionSynchronization/consumerFirstAcquisitionSynchronizationList';
import { EquipmentProductInstanceSynchronizationShow } from './forms/datasync/equipmentProductInstanceSynchronization/equipmentProductInstanceSynchronizationShow';
import { EquipmentProductInstanceSynchronizationList } from './forms/datasync/equipmentProductInstanceSynchronization/equipmentProductInstanceSynchronizationList';
import { i18nProvider } from './i18n/i18nProvider';
import { permissionService } from './auth/permissionsService';
import { lightTheme } from './layout/themes';
import { consumerTaskStepSyncronizationList } from './forms/datasync/consumerTaskStepSyncronization/consumerTaskStepSyncronizationList';
import { ConsumerTaskStepSyncronizationShow } from './forms/datasync/consumerTaskStepSyncronization/consumerTaskStepSyncronizationShow';
import { AllocationConsumerSynchronizationShow } from './forms/datasync/allocationConsumerSynchronization/allocationConsumerShow';
import { AllocationConsumerSynchronizationList } from './forms/datasync/allocationConsumerSynchronization/allocationConsumerList';
import { IntegritylogsShow } from './forms/integritylogs/integritylogsShow';
import { IntegritylogsList } from './forms/integritylogs/integritylogsList';
import { ConsumerProductInspectionRequestsSynchronizationShow } from './forms/datasync/consumerproductinspectionrequestsSynchronization/consumerProductInspectionRequestsSynchronizationShow';
import { ConsumerProductInspectionRequestsSynchronizationList } from './forms/datasync/consumerproductinspectionrequestsSynchronization/consumerProductInspectionRequestsSynchronizationList';
import { AuditShow } from './forms/audit/auditShow';
import { AuditList } from './forms/audit/auditList';
import { ImportCreate, ImportEdit, ImportList } from './forms/import';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import { LendingOrderSynchronizationList } from './forms/datasync/lendingOrderSynchronization/lendingOrdersSynchronizationList';
import { LendingOrderSynchronizationShow } from './forms/datasync/lendingOrderSynchronization/lendingOrdersSynchronizationShow';
import { SmppsettingsList } from './forms/smppsettings/smppsettingsList';
import { SmppsettingsShow } from './forms/smppsettings/smppsettingsShow';
import { SurveysList } from './forms/datasync/surveySynchronization/surveyList';
import { SurveysShow } from './forms/datasync/surveySynchronization/surveyShow';
import { SmppperformanceList } from './forms/smppperformance/smppsmppperformanceList';
import { LastRetailBrandSynchronizationList } from './forms/datasync/lastRetailBrandSynchronization/lastRetailBrandSynchronizationList';
import { LastRetailBrandSynchronizationShow } from './forms/datasync/lastRetailBrandSynchronization/lastRetailBrandSynchronizationShow';
import { LastRetailBrandReferenceSyncronizationList } from './forms/datasync/lastRetailBrandReferenceSyncronization/lastRetailBrandReferenceSyncronizationList';
import { LastRetailBrandReferenceSyncronizationShow } from './forms/datasync/lastRetailBrandReferenceSyncronization/lastRetailBrandReferenceSyncronizationShow';
import { GeographyConsumerSynchronizationList } from './forms/datasync/geographyConsumerSynchronization/geographyConsumerSynchronizationList';
import { GeographyConsumerSynchronizationShow } from './forms/datasync/geographyConsumerSynchronization/geographyConsumerSynchronizationShow';
import { LastActiveDeviceSynchronizationList } from './forms/datasync/lastActiveDeviceSynchronization/lastActiveDeviceSynchronizationList';
import { LastActiveDeviceSynchronizationShow } from './forms/datasync/lastActiveDeviceSynchronization/lastActiveDeviceSynchronizationShow';
import { LastActiveDeviceReferenceSyncronizationShow } from './forms/datasync/lastActiveDeviceReferenceSyncronization/lastActiveDeviceReferenceSyncronizationShow';
import { LastActiveDeviceReferenceSynchronizationList } from './forms/datasync/lastActiveDeviceReferenceSyncronization/lastActiveDeviceReferenceSyncronizationList';
import { LostProductsSynchronizationList } from './forms/datasync/lostProductSynchronization/lostProductsSynchronizationList';
import { LostProductsSynchronizationShow } from './forms/datasync/lostProductSynchronization/lostProductsSynchronizationShow';
import { AllBrokenList } from './forms/datasync/allBroken/allBrokenList';
import { ConsumerFirstConsumablePurchaseCancelSynchronizationList } from './forms/datasync/consumerFirstConsumablePurchaseCancelSynchronization/consumerFirstConsumablePurchaseCancelSynchronizationList';
import { ConsumerFirstConsumablePurchaseCancelSynchronizationShow } from './forms/datasync/consumerFirstConsumablePurchaseCancelSynchronization/consumerFirstConsumablePurchaseCancelSynchronizationShow';
import { ConsumerFirstConsumablePurchaseSynchronizationList } from './forms/datasync/consumerFirstConsumablePurchaseSynchronization/consumerFirstConsumablePurchaseSynchronizationList';
import { ConsumerFirstConsumablePurchaseSynchronizationShow } from './forms/datasync/consumerFirstConsumablePurchaseSynchronization/consumerFirstConsumablePurchaseSynchronizationShow';
import { ConsumerFirstAccessoryPurchaseCancelSynchronizationList } from './forms/datasync/consumerFirstAccessoryPurchaseCancelSynchronization/consumerFirstAccessoryPurchaseCancelSynchronizationList';
import { ConsumerFirstAccessoryPurchaseCancelSynchronizationShow } from './forms/datasync/consumerFirstAccessoryPurchaseCancelSynchronization/consumerFirstAccessoryPurchaseCancelSynchronizationShow';
import { ConsumerFirstAccessoryPurchaseSynchronizationList } from './forms/datasync/consumerFirstAccessoryPurchaseSynchronization/consumerFirstAccessoryPurchaseSynchronizationList';
import { ConsumerFirstAccessoryPurchaseSynchronizationShow } from './forms/datasync/consumerFirstAccessoryPurchaseSynchronization/consumerFirstAccessoryPurchaseSynchronizationShow';
import { AutoFailuredList } from './forms/datasync/allBroken/autoFailuredList';
import { SmppLogsStatList } from './forms/datasync/allBroken/smppLogsStatList';
import { FeatureFlagsCreate } from './forms/featureflags/featureflagsCreate';
import { FeatureFlagsEdit } from './forms/featureflags/featureflagsEdit';
import { FeatureFlagsList } from './forms/featureflags/featureflagsList';
import { ProductInspectionSynchronizationList } from './forms/datasync/productInspectionSynchronization/productInspectionSynchronizationList';
import { ProductInspectionSynchronizationShow } from './forms/datasync/productInspectionSynchronization/productInspectionSynchronizationShow';
import { CleaningRequestSynchronizationList } from './forms/datasync/cleaningRequestSynchronization/cleaningRequestList';
import { CleaningRequestSynchronizationShow } from './forms/datasync/cleaningRequestSynchronization/cleaningRequestShow';
import { QuestionnaireSurveySynchronizationList } from './forms/datasync/questionnaireSurveySynchronization/questionnaireSurveyList';
import { QuestionnaireSurveySynchronizationShow } from './forms/datasync/questionnaireSurveySynchronization/questionnaireSurveyShow';
import { S3serviceList } from './forms/s3services/s3serviceList';
import { S3serviceShow } from './forms/s3services/s3serviceShow';
import { FreeSMSSynchronizationShow } from './forms/datasync/freeSMSSynchronization/freeSMSSynchronizationShow';
import { FreeSMSSynchronizationList } from './forms/datasync/freeSMSSynchronization/freeSMSSynchronizationList';


function App() {
    return (
        <>
            <Admin dataProvider={httpDataProvider} authProvider={authProvider} requireAuth i18nProvider={i18nProvider} theme={lightTheme} layout={Layout}>
                {permissions => (
                    <>
                {permissionService.userHaveRoles(permissions, ['User']) && 
                    <Resource name='mts_tickets'
                          list={TicketList}
                          show={TicketShow}
                          icon={LocalActivityOutlinedIcon}
                />}
                 {permissionService.userHaveRoles(permissions, ['User', 'MTS_Rules']) && 
                    <Resource name='mts_rules'
                          list={RulesList}
                          edit={RulesEdit}
                          create={RulesCreate}
                          icon={RuleOutlinedIcon}
                />}
                {permissionService.userHaveRoles(permissions, ['User']) && 
                    <Resource name='mts_elk_alerts'
                          list={ElkAlertList}
                          icon={RuleOutlinedIcon}
                />}
                {permissionService.userHaveRoles(permissions, ['User']) && 
                    <Resource name='qk_run_export'
                          list={runExportList}
                          show={RunExportShow}
                          icon={RuleOutlinedIcon}
                />}
                {permissionService.userHaveRoles(permissions, ['User']) && 
                    <Resource name='qk_download_files'
                          list={DownloadFilesList}
                          show={DownloadFilesShow}
                          icon={RuleOutlinedIcon}
                />}
                {permissionService.userHaveRoles(permissions, ['User']) && 
                    <Resource name='qk_send_to_ucrm'
                          list={SendToUcrmList}
                          show={SendToUcrmShow}
                          icon={RuleOutlinedIcon}
                />}
                {permissionService.userHaveRoles(permissions, ['User']) && 
                    <Resource name='qk_send_to_mindbox'
                          list={SendToMindboxList}
                          show={SendToMindboxShow}
                          icon={RuleOutlinedIcon}
                />}
                {permissionService.userHaveRoles(permissions, ['User']) && 
                    <Resource name="audit_api" options={{ label: 'API Audit' }}
                            list={AuditApiList}
                            show={AuditApiShow}
                            icon={VerifiedUserIcon} />}
                {permissionService.userHaveRoles(permissions, ['User']) && 
                    <Resource name='qk_datasync_sync_consumer'
                          list={ConsumersSynchronizationList}
                          show={ConsumersSynchronizationShow}
                          icon={RuleOutlinedIcon}
                />}
                {permissionService.userHaveRoles(permissions, ['User']) && 
                    <Resource name='qk_datasync_sync_consumertasksteps'
                        list={consumerTaskStepSyncronizationList}
                        show={ConsumerTaskStepSyncronizationShow}
                        icon={RuleOutlinedIcon}
                />}
                {permissionService.userHaveRoles(permissions, ['User']) && 
                    <Resource name='qk_datasync_sync_consumercommunicationsubscription'
                          list={ConsumerCommunicationSubscriptionSynchronizationList}
                          show={ConsumerCommunicationSubscriptionSynchronizationShow}
                          icon={RuleOutlinedIcon}
                />}
                {permissionService.userHaveRoles(permissions, ['User']) && 
                    <Resource name='qk_datasync_sync_consumerproductinspectionrequests'
                          list={ConsumerProductInspectionRequestsSynchronizationList}
                          show={ConsumerProductInspectionRequestsSynchronizationShow}
                          icon={RuleOutlinedIcon}
                />}
                {permissionService.userHaveRoles(permissions, ['User']) &&  
                    <Resource name='qk_datasync_sync_consumerfirstacquisition'
                          list={ConsumerFirstAcquisitionSynchronizationList}
                          show={ConsumerFirstAcquisitionSynchronizationShow}
                          icon={RuleOutlinedIcon}
                />}
                {permissionService.userHaveRoles(permissions, ['User']) && 
                    <Resource name='qk_datasync_sync_person'
                          list={PersonsSynchronizationList}
                          show={PersonsSynchronizationShow}
                          icon={RuleOutlinedIcon}
                />}
                {permissionService.userHaveRoles(permissions, ['User']) && 
                    <Resource name='qk_datasync_sync_rrpmarketingmessagecontact'
                          list={RRPMarketingMessageContactsList}
                          show={RRPMarketingMessageContactsShow}
                          icon={RuleOutlinedIcon}
                />}
                {permissionService.userHaveRoles(permissions, ['User']) && 
                    <Resource name='qk_datasync_sync_surveys'
                          list={SurveysList}
                          show={SurveysShow}
                          icon={RuleOutlinedIcon}
                />}
                {permissionService.userHaveRoles(permissions, ['User']) && 
                    <Resource name='qk_datasync_sync_product'
                          list={ProductsSynchronizationList}
                          show={ProductsSynchronizationShow}
                          icon={RuleOutlinedIcon}
                />}
                {permissionService.userHaveRoles(permissions, ['User']) && 
                    <Resource name='qk_datasync_sync_lost_product'
                          list={LostProductsSynchronizationList}
                          show={LostProductsSynchronizationShow}
                          icon={RuleOutlinedIcon}
                />}
                {permissionService.userHaveRoles(permissions, ['User']) && 
                    <Resource name='qk_datasync_sync_equipmentproductinstance'
                          list={EquipmentProductInstanceSynchronizationList}
                          show={EquipmentProductInstanceSynchronizationShow}
                          icon={RuleOutlinedIcon}
                />}
                {permissionService.userHaveRoles(permissions, ['User']) && 
                    <Resource name='qk_datasync_sync_order'
                          list={OrdersSynchronizationList}
                          show={OrdersSynchronizationShow}
                          icon={RuleOutlinedIcon}
                />}
                {permissionService.userHaveRoles(permissions, ['User']) && 
                    <Resource name='audit'
                          list={AuditList}
                          show={AuditShow}
                          icon={VerifiedUserIcon}
                 />}
                {permissionService.userHaveRoles(permissions, ['User']) && 
                    <Resource name='qk_datasync_sync_lendingorder'
                          list={LendingOrderSynchronizationList}
                          show={LendingOrderSynchronizationShow}
                          icon={RuleOutlinedIcon}
                />}
                {permissionService.userHaveRoles(permissions, ['User']) && 
                    <Resource name='qk_datasync_sync_lastretailbrand'
                          list={LastRetailBrandSynchronizationList}
                          show={LastRetailBrandSynchronizationShow}
                          icon={RuleOutlinedIcon}
                />}
                {permissionService.userHaveRoles(permissions, ['User']) && 
                    <Resource name='qk_datasync_sync_lastretailbrandreference'
                          list={LastRetailBrandReferenceSyncronizationList}
                          show={LastRetailBrandReferenceSyncronizationShow}
                          icon={RuleOutlinedIcon}
                />}
                {permissionService.userHaveRoles(permissions, ['User']) && 
                    <Resource name='qk_datasync_sync_lastactivedevice'
                          list={LastActiveDeviceSynchronizationList}
                          show={LastActiveDeviceSynchronizationShow}
                          icon={RuleOutlinedIcon}
                />}
                {permissionService.userHaveRoles(permissions, ['User']) && 
                    <Resource name='qk_datasync_sync_lastactivedevicereference'
                          list={LastActiveDeviceReferenceSynchronizationList}
                          show={LastActiveDeviceReferenceSyncronizationShow}
                          icon={RuleOutlinedIcon}
                />}
                {permissionService.userHaveRoles(permissions, ['User']) && 
                    <Resource name='qk_datasync_sync_consumerallocations'
                          list={AllocationConsumerSynchronizationList}
                          show={AllocationConsumerSynchronizationShow}
                          icon={RuleOutlinedIcon}
                />}
                {permissionService.userHaveRoles(permissions, ['User']) && 
                    <Resource name='smpplogs'
                          list={SmppList}
                          show={SmppShow}
                          icon={RuleOutlinedIcon}
                />}
                {permissionService.userHaveRoles(permissions, ['User']) && 
                     <Resource name="imports"
                        list={<ImportList />}
                        edit={ImportEdit}
                        create={ImportCreate}
                        icon={ArrowCircleDownIcon} />
                }
                {permissionService.userHaveRoles(permissions, ['User']) && 
                    <Resource name='dataintegritylogs'
                          list={IntegritylogsList}
                          show={IntegritylogsShow}
                          icon={RuleOutlinedIcon}
                />}
                {permissionService.userHaveRoles(permissions, ['User']) && 
                    <Resource name='smppsettings'
                          list={SmppsettingsList}
                          show={SmppsettingsShow}
                          icon={RuleOutlinedIcon}
                />}
                {permissionService.userHaveRoles(permissions, ['User']) && 
                    <Resource name='smppsettings'
                          list={SmppsettingsList}
                          show={SmppsettingsShow}
                          icon={RuleOutlinedIcon}
                />}
                {permissionService.userHaveRoles(permissions, ['User']) && 
                    <Resource name='smppperformance'
                          list={SmppperformanceList}
                          icon={RuleOutlinedIcon}
                />}
                {permissionService.userHaveRoles(permissions, ['User']) && 
                    <Resource name='qk_datasync_sync_geographyconsumer'
                          list={GeographyConsumerSynchronizationList}
                          show={GeographyConsumerSynchronizationShow}
                          icon={RuleOutlinedIcon}
                />}
                {
                    <Resource name='allBrokenCount/allBrokenCount'
                          list={AllBrokenList}
                          icon={RuleOutlinedIcon}
                />}
                {permissionService.userHaveRoles(permissions, ['User']) && 
                    <Resource name='qk_datasync_sync_consumerfirstaccessorypurchase'
                    list={ConsumerFirstAccessoryPurchaseSynchronizationList}
                    show={ConsumerFirstAccessoryPurchaseSynchronizationShow}
                          icon={RuleOutlinedIcon}
                />}
                {permissionService.userHaveRoles(permissions, ['User']) && 
                    <Resource name='qk_datasync_sync_consumerfirstaccessorypurchasecancel'
                          list={ConsumerFirstAccessoryPurchaseCancelSynchronizationList}
                          show={ConsumerFirstAccessoryPurchaseCancelSynchronizationShow}
                          icon={RuleOutlinedIcon}
                />}
                {permissionService.userHaveRoles(permissions, ['User']) && 
                    <Resource name='qk_datasync_sync_consumerfirstconsumablepurchase'
                    list={ConsumerFirstConsumablePurchaseSynchronizationList}
                    show={ConsumerFirstConsumablePurchaseSynchronizationShow}
                          icon={RuleOutlinedIcon}
                />}
                {permissionService.userHaveRoles(permissions, ['User']) && 
                    <Resource name='qk_datasync_sync_consumerfirstconsumablepurchasecancel'
                          list={ConsumerFirstConsumablePurchaseCancelSynchronizationList}
                          show={ConsumerFirstConsumablePurchaseCancelSynchronizationShow}
                          icon={RuleOutlinedIcon}
                />}
                {
                    <Resource name='allBrokenCount/autoStatList'
                          list={AutoFailuredList}
                          icon={RuleOutlinedIcon}
                />}
                {
                    <Resource name='allBrokenCount/smppStatList'
                          list={SmppLogsStatList}
                          icon={RuleOutlinedIcon}
                />}
                {permissionService.userHaveRoles(permissions, ['User']) && 
                    <Resource name='featureflags'
                          list={FeatureFlagsList}
                          edit={FeatureFlagsEdit}
                          create={FeatureFlagsCreate}
                          icon={RuleOutlinedIcon}
                />}
                {permissionService.userHaveRoles(permissions, ['User']) && 
                    <Resource name='dataintegritylogs'
                          list={IntegritylogsList}
                          show={IntegritylogsShow}
                          icon={RuleOutlinedIcon}
                />}
                {permissionService.userHaveRoles(permissions, ['User']) && 
                    <Resource name='qk_datasync_sync_productinspection'
                          list={ProductInspectionSynchronizationList}
                          show={ProductInspectionSynchronizationShow}
                          icon={RuleOutlinedIcon}
                />}
                {permissionService.userHaveRoles(permissions, ['User']) && 
                    <Resource name='qk_datasync_sync_cleaningrequests'
                          list={CleaningRequestSynchronizationList}
                          show={CleaningRequestSynchronizationShow}
                          icon={RuleOutlinedIcon}
                />}
                {permissionService.userHaveRoles(permissions, ['User']) && 
                    <Resource name='qk_datasync_sync_questionnaire'
                          list={QuestionnaireSurveySynchronizationList}
                          show={QuestionnaireSurveySynchronizationShow}
                          icon={RuleOutlinedIcon}
                />}
                {permissionService.userHaveRoles(permissions, ['User']) && 
                    <Resource name='s3services'
                          list={S3serviceList}
                          show={S3serviceShow}
                          icon={RuleOutlinedIcon}
                />}
                <Resource name='s3log' />
                {permissionService.userHaveRoles(permissions, ['User']) && 
                    <Resource name='qk_datasync_sync_freesms'
                          list={FreeSMSSynchronizationList}
                          show={FreeSMSSynchronizationShow}
                          icon={RuleOutlinedIcon}
                />}
                </>)}
            </Admin>
            <div className={styles.versionFloat}>
                <div>frontend ver. {process.env.REACT_APP_VERSION}</div>
                {/* <div>backend ver. {localStorage.getItem('backendVersion')}</div> */}
            </div>
        </>
    );
}

export default App;

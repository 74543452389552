import { Grid } from "@mui/material";
import { Create, required, SelectInput, SimpleForm, TextInput, useTranslate, useRedirect, useNotify, useCreate, FileInput, FileField } from "react-admin";


export const ImportCreate = () => {
    const t = useTranslate();

    const avaliableCollectoinOptions = [
        { id: 'Baikal', name: 'Baikal' }
    ]

    const avaliableTypeOptions = [
        { id: 'Create', name: 'Create' }
    ];

    return (
        <Create>
            <SimpleForm>
                <Grid container spacing={2}>
                    <Grid item md={6} xs={12}>
                        <SelectInput fullWidth source="Collection" validate={required()} choices={avaliableCollectoinOptions} />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <SelectInput fullWidth source="Type" validate={required()} choices={avaliableTypeOptions} />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <TextInput source="Comment" multiline fullWidth />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item md={6} xs={12}>
                        <FileInput source="attachments" accept={"text/csv"}>
                            <FileField source="src" title="title" />
                        </FileInput>
                    </Grid>
                </Grid>
            </SimpleForm>
        </Create>

    );
};


import { useCallback } from "react";
import { BooleanInput, Create, FormDataConsumer, maxValue, minValue, NullableBooleanInput, NumberInput, required, SimpleForm, TextInput, useCreate, useNotify, useRedirect } from "react-admin";



export const RulesCreate = () => {
    const redirect = useRedirect();
    const notify = useNotify();
    
    const [create] = useCreate();
    const save = useCallback(
        async (values:any) => {
            try {
                values.CreatedAt = new Date()
                await create(
                    'mts_rules',
                    { data: values },
                    { returnPromise: true }
                )
                notify('ra.notification.created', {
                    type: 'info',
                    messageArgs: { smart_count: 1 },
                });
                redirect('list','mts_rules');
            } catch (error:any) {
                if (error.body.errors) {
                    Object.keys(error.body.errors).forEach(key => {
                        error.body.errors[key] = error.body.errors[key].join()
                    });
                    return error.body.errors;
                }
            }
        },
        [create, notify, redirect]
    );
    
    return (
        <Create>
            <SimpleForm onSubmit={save}>      
                <span><TextInput source="Campaign" validate={required()}/>   <NullableBooleanInput source="Dynamic" defaultValue={false} /></span>
                <NumberInput 
                    label='Life time, days (max 270)' 
                    source="LifeTime" 
                    min={1} 
                    max={270}
                    validate={[required(), minValue(1), maxValue(270)]}/>
                
                <NumberInput 
                    source='UsageLimit'
                    label='Usage limit (max 1000)'  
                    min={1} 
                    max={1000}
                    validate={[
                        required(), 
                        minValue(1), 
                        maxValue(1000)
                    ]}/>
            </SimpleForm>
        </Create>
    );
}
import { Datagrid, DateField, FunctionField, List, ListContextProvider, NumberField, TextField, TextInput, UrlField, useList, useRecordContext } from "react-admin";


const rulesFilters = [
    <TextInput source='Campaign.$contains' resettable /> 
];

export const RulesList = () =>{
    const record = useRecordContext();
    return (
        <List filters={rulesFilters} exporter={false}>
            <Datagrid rowClick='edit'>
                <TextField source='Campaign' />
                <DateField source='CreatedAt' emptyText="N/A"/>
                <FunctionField render={(record: { Dynamic: boolean; }) => record.Dynamic ? "Y": "N"} label='Dynamic (Y/N)' textAlign="center"/>
                <NumberField label='Life time, days' source='LifeTime' />
                <NumberField source="UsageLimit" />
                
            </Datagrid>
        </List>
    );
}
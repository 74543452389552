import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import { useRecordContext, useTranslate } from "ra-core";
import { SimpleShowLayout } from "ra-ui-materialui";
import { AuditValue } from "./AuditValue";


export const AuditShowTable = () => {
    const record = useRecordContext();
    const t = useTranslate();
    if (!record.KeysDifference) return null;
    return (
        <SimpleShowLayout sx={{ padding: 0 }}>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 350 }} size="small" aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ fontWeight: 'bold' }}>{t('pe.forms.audit.FieldName')}</TableCell>
                            <TableCell align="right" sx={{ fontWeight: 'bold' }}>{t('pe.forms.audit.OldValue')}</TableCell>
                            <TableCell align="right" sx={{ fontWeight: 'bold' }}>{t('pe.forms.audit.NewValue')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.entries(JSON.parse(record.Difference)).map(([key, value]: [string, any]) => (
                            <TableRow key={key} sx={{ '&:last-child td, &:last-child th': { border: 'none' } }}>
                                <TableCell component="th" scope="row">
                                    {t('resources.' + record.Collection + '.fields.' + key)}
                                </TableCell>
                                <TableCell align="right">
                                    <AuditValue value={value} isNewValue={0} />
                                </TableCell>
                                <TableCell align="right">
                                    <AuditValue value={value} isNewValue={1} />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </SimpleShowLayout>
    )
};
import { DateTimeInput, Edit, NumberInput, SaveButton, SelectInput, SimpleForm, TextInput, useRecordContext } from "react-admin";
import { Grid } from "@mui/material";
import { SmppStatuses } from "../../models/smppStatuses";


export const SmppsettingsShow = () => {
    var record = useRecordContext();
    return (
        <Edit>
            <SimpleForm toolbar={false} >
                <Grid container spacing={2} direction="row">
                    <Grid item md={6} xs={12}>
                        <TextInput source="ClientName" disabled fullWidth />
                    </Grid>
                </Grid>
                <Grid container spacing={2} direction="row">
                    <Grid item md={6} xs={12}>
                        <NumberInput source="MessagesPerSecond" fullWidth />
                    </Grid>
                </Grid>
                <SaveButton sx={{ margin: 2 }} label="Save" type="button"/>
            </SimpleForm>
        </Edit>
    );
}
import { Datagrid, DateField, DateInput, FunctionField, List, ListContextProvider, NumberField, SelectInput, TextField, TextInput, UrlField, useList, useRecordContext } from "react-admin";
import { QueuekickState } from "../../../models/queuekickState";
import {  SendToUcrmShow } from "./sendToUcrmShow";
import { QueueKickStatuses } from "../../../models/queuekickStatuses";


const postFilters = [
    <TextInput source="Params.$contains" label="Params" />,
    <DateInput source="Created.$ge" label="Created From" />,
    <DateInput source="Created.$le" label="Created To" />,
    <DateInput source="Updated.$ge" label="Updated From" />,
    <DateInput source="Updated.$le" label="Updated To" />,
    <SelectInput source="State" choices={QueueKickStatuses} />
];

export const SendToUcrmList = () =>{
    return (
        <List filters={postFilters} exporter={false} title='Subscriptions from Mindbox to UCRM'>
            <Datagrid bulkActionButtons={false} expand={<SendToUcrmShow/>} rowClick='expand' expandSingle>
                <DateField source='Created' showTime />
                <DateField source='StartTime' label='Started' showTime />
                <DateField source='Updated' label='Last update' showTime />
                <FunctionField label='State' render={(record: any) => QueuekickState[record.State]} />
                <NumberField source='CurrentAttemptCount' label='Current attempt' />
            </Datagrid>
        </List>
    );
}
export const ra = {
    ra: {
        action:{
            add_filter:{
                en: 'Manage filters',
                ru: 'Управление фильтрами'
            },
            select_columns: {
                en: 'Columns',
                ru: 'Колонки'
            },
        }
    }
}
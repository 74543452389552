import { ReactNode, createElement } from 'react';
import {
    List,
    MenuItem,
    ListItemIcon,
    Typography,
    Collapse,
    Tooltip,
} from '@mui/material';
import KeyboardArrowRightTwoToneIcon from '@mui/icons-material/KeyboardArrowRightTwoTone';
import { useTranslate, useSidebarState } from 'react-admin';
import { ReactComponentLike } from 'prop-types';

interface Props {
    dense: boolean;
    handleToggle: () => void;
    icon: ReactComponentLike;
    isOpen: boolean;
    isMenuOpen: boolean;
    isPrimary: boolean;
    name: string;
    children: ReactNode;
}

const SubMenu = (props: Props) => {
    const { handleToggle, isOpen, isMenuOpen, isPrimary, name, icon, children, dense } = props;
    const translate = useTranslate();

    const [sidebarIsOpen, setSidebarOpen] = useSidebarState();

    const handleClick = () => {
        !sidebarIsOpen && setSidebarOpen(true);
        handleToggle();
    }

    const header = (
        <MenuItem
            dense={dense}
            onClick={handleClick}
            sx={{
                marginTop: '5px',
                backgroundSize: '5px',
                backgroundPosition: '0px',
                backgroundRepeat: 'no-repeat',
                backgroundImage: isPrimary ? 'linear-gradient(#005396,  #005396 )' : 'none',
                borderTopLeftRadius: isPrimary ? 0 : '3.31667px',
                borderTopRightRadius: '3.31667px',
                borderBottomLeftRadius: isPrimary ? 0 : '3.31667px',
                borderBottomRightRadius: '3.31667px',
                width: isMenuOpen ? '260px' : '45px',
                paddingLeft: '20px',
                '.groupName': {
                    transition:
                        'all 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
                },
                '&:hover': isPrimary ? {} : {
                    '.groupName': {
                        marginLeft: '20px',
                    }
                }
            }}>
            <ListItemIcon sx={{ minWidth: 5 }}>
                {createElement(icon, { color: isPrimary ? 'primary' : 'action' })}
            </ListItemIcon>
            <Typography className={'groupName'} variant="inherit" color={isPrimary ? 'primary' : 'action'} fontWeight={600}>
                {translate(name)}
            </Typography>
            <ListItemIcon className={'groupIcon'} sx={{ minWidth: 5, margin: '0 0 0 10px' }}>
                <KeyboardArrowRightTwoToneIcon
                    color={isPrimary ? 'primary' : 'action'}
                    sx={{
                        transform: isOpen ? 'rotate(90deg)' : 'rotate(0deg)',
                        transition: 'transform 0.2s'
                    }} />
            </ListItemIcon>
        </MenuItem>
    );

    return (
        <div>
            {sidebarIsOpen || isOpen ? (
                header
            ) : (
                <Tooltip title={translate(name)} placement="right">
                    {header}
                </Tooltip>
            )}
            <Collapse in={isOpen && sidebarIsOpen} timeout="auto" unmountOnExit>
                <List
                    dense={dense}
                    component="div"
                    disablePadding
                    sx={{
                        '& a': {
                            transition:
                                'all 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
                            paddingLeft: '20px',
                            opacity: sidebarIsOpen ? '1' : '0.1',
                            borderRadius: '3.31667px',
                            width: '260px',
                            '&:hover': {
                                marginLeft: '20px',
                            }
                        },
                        '& .RaMenuItemLink-active': {
                            fontWeight: '700',
                            backgroundColor: (theme: any) => {
                                const { mode, grey } = theme.palette
                                return mode === 'dark' ? grey[500] : grey[300]
                            },
                            width: '260px',
                            marginLeft: '20px',
                        }
                    }}
                >
                    {children}
                </List>
            </Collapse>
        </div>
    );
};

export default SubMenu;
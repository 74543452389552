import { ArrayField, BooleanField, Button, Datagrid, DateField, Edit, FunctionField, ListContextProvider, NumberField, Pagination, ReferenceArrayField, ReferenceField, ReferenceManyField, ReferenceOneField, RichTextField, Show, SimpleShowLayout, SingleFieldList, TextField, TextInput, UrlField, useList, UseListOptions, useRecordContext } from "react-admin";
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { Card, CardContent, Divider, Grid, Tooltip } from "@mui/material";
import { useState } from "react";


export const ElkAlertShow = () => {
    var record = useRecordContext();
    return (
        <SimpleShowLayout>
        {/* <Card> */}
            {/* <CardContent> */}
            {/* <ListContextProvider value={listContext}> */}
            {/* <ArrayField source='Requests'> */}
                <ReferenceArrayField reference="mts_ticketrequests" 
                    source="LastTicketRequestIds" 
                    sort={{ field: 'Created', order: 'DESC' }}
                    label='Last invalid requests'>
                    <Datagrid bulkActionButtons={false}>
                        <DateField source="Created" showTime={true} />
                        <TextField source="ErrorMessage" />
                    </Datagrid>
                </ReferenceArrayField>
            {/* </ListContextProvider> */}
            {/* </ArrayField> */}
            {/* </CardContent> */}
        {/* // </Card> */}
        </SimpleShowLayout>
    );
}
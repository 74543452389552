import { Datagrid, List, NumberField, TextField } from "react-admin";

export const AllBrokenList = () =>{
    return (
        <List exporter={false} title='Broken count from DataSync'>
            <Datagrid >
                <TextField source='queue' />
                <NumberField source='count' />
            </Datagrid>
        </List>
    );
}
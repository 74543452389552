import { useEffect, useState } from "react";
import { useGetList, useGetOne, useResourceDefinitions, useTranslate } from "react-admin";
import Box from '@mui/material/Box';
import { Link } from "@mui/material";
import MenuEntriesService, { MenuEntries } from './menuEntriesService';

interface navLink {
    title: string,
    link: string,
    name: string
}

export const useGetPagelocation = () => {

    const [menuGroup, setMenuGroup] = useState<MenuEntries | null>(null);
    const [resource, setResource] = useState<navLink | null>(null);
    const [recordId, setRecordId] = useState<string | null>(null);
    const [currentTabIndex, setCurrentTabIndex] = useState<number>(0);
    const resources = useResourceDefinitions();
    const createLinkPath = 'create';
    const isGettingResourceEnable = !!resource?.link && !!recordId && recordId !== createLinkPath;
    const { data: recordsData } = useGetList(resource?.link as string, { filter: { id: recordId } }, { enabled: isGettingResourceEnable });
    const recordData = !!recordsData && recordsData?.length > 0 && recordsData[0];

    const t = useTranslate();

    const url = window.location.href;
    const menuEntriesService = new MenuEntriesService(resources);

    useEffect(() => {
        const resourceNameArray = url.match(/(?<=#\/)[a-zA_Z]*(?=\/|\?|$)/gm) as RegExpMatchArray;
        const resourceName = resourceNameArray?.length > 0 && resourceNameArray[0];
        const urlRecordIdArray = url.match(/(?<=#\/[a-zA_Z]*\/)[^\/|\?]*(?=\/|\?|$)/g) as RegExpMatchArray;
        const urlRecordId = urlRecordIdArray?.length > 0 && urlRecordIdArray[0];
        const currentTabIndexArray = url.match(/(?<=#\/[a-zA_Z]*\/[^\/|\?]*\/)[^\/|\?]*(?=\/|\?|$)/g) as RegExpMatchArray;
        const currentTabIndex = currentTabIndexArray?.length > 0 ? Number(currentTabIndexArray[0]) : 0;

        setCurrentTabIndex(currentTabIndex);

        if (!!resourceName) {
            const group = menuEntriesService.getGroupByResourceName(resourceName);

            if (!!group && group !== menuGroup) {
                setMenuGroup(group);
            }
            if (!group) {
                setMenuGroup(null);
            }

            if (!!resourceName && resourceName !== resource?.title) {
                setResource({ title: menuEntriesService.getTitleFromResourceName(resourceName), link: resourceName, name: resourceName });
            }
        }

        if (!resourceName) {
            setResource(null);
            setMenuGroup(null);
        }

        if (!!urlRecordId) {
            setRecordId(urlRecordId);
        } else {
            setRecordId(null);
        }

    }, [url])

    const recordName = recordData?.Name || recordData?.Title || recordData?.Code || recordData?.id;

    const breadCrumbs = <Box sx={{
        marginTop: '16px',
        '> *+*::before': {
            display: 'inline-block',
            content: '">"',
            margin: '0 12px',
        },
        '> div, > span, > a': {
            color: (theme) => theme.palette.text.secondary,
        },
        '>:last-child': {
            fontWeight: '700'
        }
    }}>
        {!!menuGroup && <Box component={'span'} sx={{ cursor: "default" }}>
            {t(`cc.menuGroups.labels.${menuGroup}`)}
        </Box>}
        {!!resource && <Link href={`#/${resource?.link}`} underline="none">
            {resource?.title}
        </Link>}
        {!!recordId && recordId === createLinkPath && <Box component={'span'} sx={{ cursor: "default" }}>
            {t(`cc.breadCrumbs.create`)}
        </Box>}
        {!!recordData && <Link href={`#/${resource?.link}/${recordId}`} underline="none">
            {recordName}
        </Link>}
    </Box>

    return { menuGroup, resource, recordId, recordName, currentTabIndex, breadCrumbs }
}
import { Grid } from "@mui/material";
import { DateTimeInput, Show, SimpleForm, TextInput, useTranslate } from "react-admin";

export const AuditApiShow = () => {

    const t = useTranslate();
    return (
        <Show>
            <SimpleForm toolbar={false} >
                <Grid container spacing={2} direction="row">
                    <Grid item md={6} xs={12}>
                        <TextInput source="Code" disabled fullWidth />
                    </Grid>
                </Grid>
                <Grid container spacing={2} direction="row">
                    <Grid item md={6} xs={12}>
                        <DateTimeInput source='Timestamp' disabled fullWidth />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <TextInput source="Url" disabled fullWidth />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <TextInput source="Method" disabled fullWidth />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <TextInput source="QueryString" disabled fullWidth />
                    </Grid>
                </Grid>
                <Grid container spacing={2} direction="row">
                    <Grid item xs={12}>
                        <TextInput source="Request" multiline disabled fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput source="Response" multiline disabled fullWidth />
                    </Grid>
                </Grid>
            </SimpleForm>
        </Show>
    );
};
import { Card, CardContent } from '@mui/material';
import { Title, List, Datagrid, TextField, DateField, FunctionField, useTranslate, FilterList, FilterListItem, TextInput, FilterForm } from 'react-admin';
import CategoryIcon from '@mui/icons-material/LocalOffer';
import { Stack } from '@mui/system';
import { AuditEditFields } from '../../components/AuditEditFields';
import * as XLSX from 'xlsx';


const exporter = (auditItems: any[]) => {
    let modifiedAuditItems = auditItems.map((item) => {
        if (item.Difference) {
            item.Difference = Object.entries(JSON.parse(item.Difference)).map(([key, value]: [string, any]) => {
                return {
                    Field: key,
                    OldValue: value[0],
                    NewValue: value.length > 1 && value[1]
                };
            });
            item.Difference = JSON.stringify(item.Difference);
        }
        return item;
    });
    const worksheet = XLSX.utils.json_to_sheet(modifiedAuditItems);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'AuditItems');
    XLSX.writeFile(workbook, 'AuditItems.xlsx');
}

const postFilters = [
    <TextInput label="Search User" source="UserId.$contains" alwaysOn />,
    <TextInput label="Search Original" source="OldValue.$contains" alwaysOn />,
    <TextInput label="Search New" source="NewValue.$contains" alwaysOn />,
];

const ListToolbar = () => (
    <Stack direction="row" justifyContent="space-between" width="100%">
        <FilterForm filters={postFilters} />
    </Stack>
)


export const PostFilterSidebar = () => {
    return (
        <Card sx={{ order: -1, mr: 2, mt: 4, width: 200 }}>
            <CardContent>
                <ListToolbar />
                <FilterList label="resources.audit.fields.AuditType" icon={<CategoryIcon />}>
                    <FilterListItem label="resources.audit.statuses.Create" value={{ AuditType: "Create" }} />
                    <FilterListItem label="resources.audit.statuses.Edit" value={{ AuditType: "Edit" }} />
                    <FilterListItem label="resources.audit.statuses.Delete" value={{ AuditType: "Delete" }} />
                </FilterList>
            </CardContent>
        </Card>
    )
};


export const AuditList = () => {
    const t = useTranslate();
    return (
        <div>
            <Title title="Audit" />
            <List aside={<PostFilterSidebar />} exporter={exporter} sort={{ field: "Timestamp", order: "DESC" }}>
                <Datagrid rowClick="show" bulkActionButtons={false}>
                    <DateField source='Timestamp' showTime locales="ru-RU" />
                    <TextField source="UserId" />
                    <FunctionField label={t('resources.audit.fields.AuditType')} render={(record: any) => `${t('resources.audit.statuses.' + record.AuditType)}`} />
                    <FunctionField label={t('resources.audit.fields.Collection')} render={(record: any) => `${t('pe.general.' + record.Collection)}`} />
                    <AuditEditFields label={t('resources.audit.fields.KeysDifference')} />
                </Datagrid>
            </List>
        </div>)
};
import { Title, List, Datagrid, TextField, DateField, useTranslate } from 'react-admin';
import * as XLSX from 'xlsx';


const exporter = (auditItems: any[]) => {
    const worksheet = XLSX.utils.json_to_sheet(auditItems);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'AuditApiItems');
    XLSX.writeFile(workbook, 'AuditApiItems.xlsx');
}


export const AuditApiList = () => {
    const t = useTranslate();
    return (
        <div>
            <Title title="Audit Api" />
            <List  exporter={exporter}>
                <Datagrid rowClick="show" sort={{ field: "Timestamp", order: "DESC" }} bulkActionButtons={false}>
                    <DateField source='Timestamp' showTime />
                    <TextField source="Url" />
                    <TextField source="Method" />
                </Datagrid>
            </List>
        </div>)
};
import { Datagrid, List, NumberField, TextField } from "react-admin";

export const SmppLogsStatList = () =>{
    return (
        <List exporter={false} title='Smpp logs stat list'>
            <Datagrid >
                <TextField source='queue' label='Title'/>
                <NumberField source='count' />
            </Datagrid>
        </List>
    );
}
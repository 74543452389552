import { Datagrid, DateField, DateInput, FunctionField, List, ListContextProvider, NumberField, SelectField, SelectInput, TextField, TextInput, UrlField, useList, useRecordContext } from "react-admin";
import { SmppStatuses } from "../../models/smppStatuses";
import * as XLSX from 'xlsx';


export const SmppsettingsList = () =>{
    return (
        <List>
            <Datagrid rowClick="show" sx={{
                '& .column-MessagesPerSecond': { textAlign: 'left' },
            }}>
                <TextField source="ClientName" />
                <NumberField source="MessagesPerSecond" />
            </Datagrid>
        </List>
    );
}
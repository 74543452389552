import { ArrayField, Datagrid, EditContextProvider, NumberField, Pagination, SaveButton, SimpleForm, SimpleList, SimpleShowLayout, SingleFieldList, TextField, UrlField, useEditController, useNotify, useRecordContext, useUpdate } from "react-admin"
import { QueuekickState } from "../../../models/queuekickState";
import RestartAltOutlinedIcon from '@mui/icons-material/RestartAltOutlined';

export const SendToUcrmShow = () =>{
    var viewRecord = useRecordContext();
    var editProps = useEditController({ resource: 'qk_send_to_ucrm', id: viewRecord.id });
    const notify = useNotify();
    const [update] = useUpdate();

    async function handleClick (e : any) {
        e.preventDefault();
        console.log(editProps);
        await update(
            'qk_send_to_ucrm',
            { 
                id: editProps.record.id, 
                data: {...editProps.record, 
                    State: QueuekickState.Pending,
                    MaxAttemptCount: editProps.record.MaxAttemptCount + 1
                } 
            },
            { 
                onSuccess: () =>  notify('Restarted', {type: 'success'}),
                onError: () => notify('Cannot be restarted', {type:'error'})
            }
        );
        
    };
    return (
        <SimpleShowLayout>
            <TextField source='Result' emptyText={"No result"}/>
            <TextField source='Params' emptyText={"No result"}/>
            <EditContextProvider value={editProps}>
                <SimpleForm toolbar={false}>
                    <SaveButton onClick={handleClick} type="button" alwaysEnable label="Restart" icon={<RestartAltOutlinedIcon />}/>
                </SimpleForm>
            </EditContextProvider>

        </SimpleShowLayout>
    );
}
import { useCallback } from "react";
import { ArrayField, Datagrid, EditContextProvider, FileField, NumberField, Pagination, SaveButton, SimpleForm, SimpleList, SimpleShowLayout, SingleFieldList, TextField, UrlField, useEditController, useNotify, useRecordContext, useUpdate } from "react-admin"
import { useFormContext } from "react-hook-form";
import { QueuekickState } from "../../../models/queuekickState";
import RestartAltOutlinedIcon from '@mui/icons-material/RestartAltOutlined';

export const DownloadFilesShow = () =>{
    var viewRecord = useRecordContext();
    var errorObject;
    var resultObject = JSON.parse(viewRecord.Result);
    var successResult = viewRecord.State = QueuekickState.Success && typeof(resultObject) === 'string';

    var editProps = useEditController({ resource: 'qk_download_files', id: viewRecord.id });
    const notify = useNotify();
    const [update] = useUpdate();
    
    async function handleClick (e : any) {
        e.preventDefault();
        console.log(editProps);
        await update(
            'qk_download_files',
            { 
                id: editProps.record.id, 
                data: {...editProps.record, 
                    State: QueuekickState.Pending,
                    MaxAttemptCount: editProps.record.MaxAttemptCount + 1
                } 
            },
            { 
                onSuccess: () =>  notify('Restarted', {type: 'success'}),
                onError: () => notify('Cannot be restarted', {type:'error'})
            }
        );
        
    };
    return (
        <SimpleShowLayout>
            {successResult &&
                <UrlField record={{Result: resultObject}} source="Result" target={'_blank'} label="Link to file"/>
            }
            {!successResult &&
                <TextField record={{Err: resultObject.error}} source='Err' emptyText={"No result"} label="Error message"/>
            }
            <EditContextProvider value={editProps}>
                <SimpleForm toolbar={false}>
                    <SaveButton onClick={handleClick} type="button" alwaysEnable label="Restart" icon={<RestartAltOutlinedIcon />}/>
                </SimpleForm>
            </EditContextProvider>
        </SimpleShowLayout>
    );
}
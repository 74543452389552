import { DateTimeInput, Edit, SaveButton, SelectInput, SimpleForm, TextInput, useRecordContext } from "react-admin";
import { Grid } from "@mui/material";
import { SmppStatuses } from "../../models/smppStatuses";


export const SmppShow = () => {
    var record = useRecordContext();
    return (
        <Edit>
            <SimpleForm toolbar={false} >
                <Grid container spacing={2} direction="row">
                    <Grid item md={6} xs={12}>
                        <DateTimeInput source='Created' disabled fullWidth />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <TextInput source="From" disabled fullWidth />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <TextInput source="Service" disabled fullWidth />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <TextInput source="To" disabled fullWidth />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <SelectInput
                            fullWidth
                            source="Status"
                            choices={SmppStatuses} />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <DateTimeInput source="ClickDate" disabled fullWidth defaultValue={"dd.mm.yyyy hh:mm"}/>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <DateTimeInput source="SeenDate" disabled fullWidth defaultValue={"dd.mm.yyyy hh:mm"}/>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <DateTimeInput source="DeliveryDate" disabled fullWidth defaultValue={"dd.mm.yyyy hh:mm"}/>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <TextInput source="RootActionId" disabled fullWidth />
                    </Grid>
                </Grid>
                <Grid container spacing={2} direction="row">
                    <Grid item xs={12}>
                        <TextInput source="Message" multiline disabled fullWidth />
                    </Grid>
                </Grid>
                <SaveButton sx={{ margin: 2 }} label="Save" type="button" alwaysEnable  transform={(data: any) => ({ ...data, NeedUpdate: true })}/>
            </SimpleForm>
        </Edit>
    );
}